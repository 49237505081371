<template>
    <div class="creationCompetitionOne">
        <el-form :rules="rules" ref="form" :model="form" label-width="80px" label-position="left">
            <el-form-item label="竞赛名称" prop="name">
                <el-input style="width: 500px;" v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="竞赛介绍">
                <el-input type="textarea" :rows="4" style="width: 500px;" v-model="form.introduce"></el-input>
            </el-form-item>
            <el-form-item label="行业分类" prop="industryClassify">
                <el-select v-model="form.industryClassify" placeholder="请选择行业分类" style="width: 500px;">
                    <el-option v-for="(item,index) in industryList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="开始时间" prop="startTime">
                <el-date-picker
                        v-model="form.startTime"
                        @change="selectStartTime"
                        :picker-options="pickerOptions"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择开始日期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间" prop="overTime">
                <el-date-picker
                        v-model="form.overTime"
                        @change="selectOverTime"
                        :picker-options="pickerOptions"
                        type="datetime"
                        value-format="timestamp"
                        placeholder="选择结束日期时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="队伍" prop="teamClassify">
                <el-select v-model="form.teamClassify" placeholder="请选择队伍" style="width: 500px;">
                    <el-option v-for="(item,index) in teamList" :key="index" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <div class="upDownBtn">
            <el-button @click="toBack">上一步</el-button>
            <el-button @click="toNext" style="background: #1122D8;border-color:#1122D8;color: #ffffff;margin-left: 20px">下一步</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "creationCompetitionOne",
        data(){
            return {
                form:{
                    name:'',
                    introduce:'',
                    industryClassify:'',
                    startTime:'',
                    overTime:'',
                    teamClassify:''
                },
                editForm:{},
                industryList:[],
                teamList:[],
                classId: null,
                pickerOptions:{
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 3600 * 1000 * 24;
                    }
                },
                rules:{
                    name: [
                        { required: true, message: '请输入竞赛名称', trigger: 'blur' },
                        { min: 3, max: 50, message: '长度在 3 到 50 个字符', trigger: 'blur' }
                    ],
                    industryClassify: [
                        { required: true, message: '请选择行业分类', trigger: 'change' }
                    ],
                    startTime: [
                        { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                    ],
                    overTime: [
                        { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                    ],
                    teamClassify: [
                        { required: true, message: '请选择队伍', trigger: 'change' }
                    ]
                }
            }
        },
        created(){
            //编辑
            if (this.$route.query.editRes) {
                this.getEditData(this.$route.query.editRes).then((res) => {
                    this.classId = res.c_id;
                    this.getClassesList();
                });
            }
            this.getCategoryList();
        },
        methods: {
            getEditData(id){
                let _this = this;
                return new Promise(function(resolve, reject) {
                    _this.$http.axiosGetBy(_this.$api.getCom, {com_id:id},res=>{
                        if(res.code === 200){
                            _this.editForm = res.data;
                            resolve(res.data);
                            let date1 = new Date(res.data.begin_time);
                            let date2 = new Date(res.data.end_time);
                            let time1 = date1.getTime();
                            let time2 = date2.getTime();
                            _this.form.name = res.data.name;
                            _this.form.industryClassify = res.data.category_id;
                            _this.form.startTime = time1;
                            _this.form.overTime = time2;
                            _this.form.teamClassify = res.data.c_id;
                            if(res.data.introduce){
                                _this.form.introduce = res.data.introduce
                            }
                        } else {
                            _this.$message.warning(res.msg)
                            reject(new Error(`${res.msg}`));
                        }
                    })

                });

            },
            //行业分类
            getCategoryList(){
                this.$http.axiosGetBy(this.$api.categoryList, {}, res=>{
                    if(res.code === 200){
                        this.industryList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            //队伍
            getClassesList(){
                let param = {
                    begin_time: this.form.startTime/1000,
                    end_time: this.form.overTime/1000
                };
                if (this.classId) {
                    param.class_id = this.classId;
                }
                this.$http.axiosGetBy(this.$api.classes, param, res=>{
                    if(res.code === 200){
                        this.teamList = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                })
            },
            // 选择开始时间
            selectStartTime(val){
            },
            selectOverTime(val){
                if(!this.form.startTime){
                    this.$message.warning('请先选择开始时间！');
                    this.form.overTime = ''
                } else {
                    if(this.form.overTime < this.form.startTime){
                        this.$message.warning('请选择开始时间之后的日期！');
                        this.form.overTime = ''
                    } else {
                        this.getClassesList()
                    }
                }
            },
            toBack(){
                this.$router.push({
                    path:'/schooladmin/competition'
                })
            },
            toNext(){
                if(this.form.name && this.form.industryClassify && this.form.startTime && this.form.overTime && this.form.teamClassify){
                    let formParam = JSON.stringify(this.form);
                    if(this.$route.query.editRes){
                        let editForm = JSON.stringify(this.editForm);
                        this.$router.push({
                            path:'/schooladmin/competition/creationcompetitiontwo',
                            query:{
                                res:formParam,
                                editForm:editForm,
                                editRes:this.$route.query.editRes
                            }
                        })
                    } else {
                        this.$router.push({
                            path:'/schooladmin/competition/creationcompetitiontwo',
                            query:{
                                res:formParam,
                            }
                        })
                    }
                } else {
                    this.$message.warning('必填项不能为空！')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .creationCompetitionOne{
        padding:40px 0 70px 120px;
    }
    .upDownBtn{
        padding-left: 80px;
    }
</style>